import IndexedDB from "@/core/services/indexeddb.service";
import ApiService from "@/core/services/api.service";

const state = {
  addresses: [],
  visibleAddresses: [],
  loadedAddressesCount: 0,
  maxAddressesCount: 0,
  lastUpdateTimeStamp: localStorage.getItem("lastAddressUpdateTimeStamp") || ""
};

const mutations = {
  increaseLoadedAddressesCount(state) {
    state.loadedAddressesCount++;
  },
  setAddresses(state, addresses) {
    state.addresses = addresses;
  },
  setVisibleAddresses(state, addresses) {
    state.visibleAddresses = addresses;
  },
  addVisibleAddress(state, address) {
    state.visibleAddresses.push(address);
  },
  clearVisibleAddresses(state) {
    state.visibleAddresses = [];
  },
  setLastUpdateTimeStamp() {
    let time = new Date();

    let timeStamp = // Y-m-d H:i:s
      time.getFullYear() +
      "-" +
      (time.getMonth() + 1 < 10 ? "0" : "") +
      (time.getMonth() + 1) +
      "-" +
      (time.getDate() < 10 ? "0" : "") +
      time.getDate() +
      " " +
      (time.getHours() < 10 ? "0" : "") +
      time.getHours() +
      ":" +
      (time.getMinutes() < 10 ? "0" : "") +
      time.getMinutes() +
      ":" +
      (time.getSeconds() < 10 ? "0" : "") +
      time.getSeconds();

    localStorage.setItem("lastAddressUpdateTimeStamp", timeStamp);
  }
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async checkAddressStorage({ state, commit }) {
    try {
      let data = await IndexedDB.getStorage("addresses"); // IndexedDB did not find the data, try localStorage

      // if (data === undefined) data = ls.checkStorage(field)  // LocalStorage did not find the data, reset it
      if (data === null) data = [];
      // commit("setState", data);
    } catch (e) {
      // The value in storage was invalid or corrupt so just set it to blank
      // commit("setState", []);
    }

    return "checked storage";
  },
  increaseLoadedAddressesCount({ commit }) {
    commit("increaseLoadedAddressesCount");
  },
  // eslint-disable-next-line no-unused-vars
  async updateAddresses({ commit, state }, force = false) {
    let addresses = state.addresses;

    if (addresses.length === 0 || force) {
      await ApiService.post("addresses", {
        date: state.lastUpdateTimeStamp
      }).then(response => {
        addresses = response.data.items;
        state.maxAddressesCount = response.data.count;
      });

      state.loadedAddressesCount = 0;

      try {
        for (let i = 0; i < addresses.length; i++) {
          let currAddress = addresses[i];

          await IndexedDB.saveToStorage("addresses", {
            key: currAddress.address.id,
            value: currAddress
          });

          state.loadedAddressesCount++;
        }

        commit("setLastUpdateTimeStamp");
      } catch (e) {
        // console.error(e);
      }
    }
  },
  setVisibleAddresses({ commit }, addresses) {
    commit("setVisibleAddresses", addresses);
  },
  addVisibleAddress({ commit }, address) {
    commit("addVisibleAddress", address);
  },
  clearVisibleAddresses({ commit }) {
    commit("clearVisibleAddresses");
  }
};

const getters = {
  async addresses() {
    return await IndexedDB.getStorage("addresses");
  },
  loadedAddressesCount: state => state.loadedAddressesCount,
  maxAddressesCount: state => state.maxAddressesCount,
  visibleAddresses: state => state.visibleAddresses,
  lastUpdateTimeStamp: state => state.lastUpdateTimeStamp
};

export default {
  state,
  mutations,
  actions,
  getters
};
