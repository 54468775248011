import ApiService from "@/core/services/api.service";

const state = {
  status: null,
  unlockedStatusGroups: ["gnv", "buildconn"],
  filter: {}
};

const mutations = {
  setStatus: (state, status) => {
    state.status = status;
  },
  setActiveStatusGroup: (state, statusGroup) => {
    state.activeStatusGroup = statusGroup;
  },
  setActiveStatusArray: (state, statusArray) => {
    state.activeStatusArray = statusArray;
  },
  setFilter: (state, filter) => {
    state.filter = filter;
  },
  setStatusFilter: (state, statusFilter) => {
    state.filter.status = statusFilter;
  }
};

const actions = {
  async updateStatus({ commit, state }) {
    let status = state.status;

    await ApiService.get("status").then(response => {
      status = response.data;
    });

    commit("setStatus", status);
  },
  setActiveStatusGroup({ commit }, statusGroup) {
    commit("setActiveStatusGroup", statusGroup);
  },
  setActiveStatusArray({ commit }, statusArray) {
    commit("setActiveStatusArray", statusArray);
  },
  setFilter({ commit }, filter) {
    commit("setFilter", filter);
  },
  setStatusFilter({ commit }, statusFilter) {
    commit("setStatusFilter", statusFilter);
  }
};

const getters = {
  status: state => state.status,
  filter: state => state.filter,
  statusGroups(state) {
    let result = [];

    let statusMeta = state.status ? state.status.meta.overall : {};

    for (const [key, value] of Object.entries(statusMeta)) {
      if (state.unlockedStatusGroups.includes(key)) {
        result.push({
          text: value.form.de_DE.caption,
          value: {
            key,
            statusGroup: value
          }
        });
      }
    }

    return result;
  },
  statusGroupFilterCount: state => () => {
    const statusFilter = state.filter.status;
    let counter = 0;

    for (const statusGroup of Object.entries(statusFilter)) {
      if (statusGroup[1].length > 0) {
        counter++;
      }
    }

    return counter;
  },
  firstStatusGroupKey: state => () => {
    const statusFilter = state.filter.status;

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(statusFilter)) {
      if (value.length > 0) {
        return key;
      }
    }

    return null;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
